<template>
  <div ref="resizableRef" class="resizable" :style="style">
    <slot></slot>
    <div class="resize-handle" @mousedown="initResize" @touchstart="initResize">
      <Resize></Resize>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, defineEmits } from "vue";
import Resize from "./Icons/Resize.vue";

const resizableRef = ref(null);
const width = ref(600);
const height = ref(350);
const emit = defineEmits(["resizing"]);

const style = computed(() => ({
  width: `${width.value}px`,
  height: `${height.value}px`,
  position: "relative",
  overflow: "hidden",
  border: "1px solid #ccc",
  borderRadius: "5px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  background: "#f0f0f0",
}));

const initResize = (event) => {
  event.preventDefault();
  emit("resizing", true);

  document.addEventListener("mousemove", resizeElement);
  document.addEventListener("mouseup", stopResize);
  document.addEventListener("touchmove", resizeElement);
  document.addEventListener("touchend", stopResize);
};

const resizeElement = (event) => {
  let clientX, clientY;

  if (typeof TouchEvent !== "undefined" && event instanceof TouchEvent) {
    clientX = event.touches[0].clientX;
    clientY = event.touches[0].clientY;
  } else {
    clientX = event.clientX;
    clientY = event.clientY;
  }

  if (resizableRef.value) {
    const boundingRect = resizableRef.value.getBoundingClientRect();
    const newWidth = clientX - boundingRect.left;
    const newHeight = clientY - boundingRect.top;

    width.value = Math.max(newWidth, 100);
    height.value = Math.max(newHeight, 100);
  }
};

const stopResize = () => {
  emit("resizing", false);

  document.removeEventListener("mousemove", resizeElement);
  document.removeEventListener("mouseup", stopResize);
  document.removeEventListener("touchmove", resizeElement);
  document.removeEventListener("touchend", stopResize);
};

onBeforeUnmount(() => {
  stopResize();
});
</script>

<style scoped>
.resizable {
  position: relative;
}

.resize-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: se-resize;
  background: rgba(0, 0, 0, 0.2);
}
</style>
