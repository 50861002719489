<template>
  <div
    ref="draggableRef"
    class="draggable"
    :style="style"
    @mousedown="handleMouseDown"
    @touchstart="handleMouseDown"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, watch, defineProps } from "vue";
import { useDraggable } from "@vueuse/core";

const props = defineProps({
  isResizing: {
    type: Boolean,
    default: false,
  },
});

const draggableRef = ref(null);
const x = ref(0);
const y = ref(0);
const positionType = ref("absolute");

const style = computed(() => ({
  transform: `translate(${x.value}px, ${y.value}px)`,
  position: positionType.value,
}));

onMounted(async () => {
  await nextTick();
  if (draggableRef.value) {
    const savedPosition = JSON.parse(
      localStorage.getItem("draggable-position")
    );
    const initialPosition = savedPosition || { x: 0, y: 0 };
    x.value = initialPosition.x;
    y.value = initialPosition.y;
    if (savedPosition) {
      positionType.value = "fixed";
    }

    const { x: dragX, y: dragY } = useDraggable(draggableRef, {
      initialValue: { x: initialPosition.x, y: initialPosition.y },
      axis: "both",
    });

    watch([dragX, dragY], ([newX, newY]) => {
      if (!props.isResizing) {
        x.value = newX;
        y.value = newY;
        localStorage.setItem(
          "draggable-position",
          JSON.stringify({ x: newX, y: newY })
        );
      }
    });
  }
});

const handleMouseDown = () => {
  if (positionType.value !== "fixed") {
    positionType.value = "fixed";
  }
};
</script>

<style scoped>
.draggable {
  cursor: move;
  z-index: 10000;
  top: 0;
  left: 0;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  user-select: none;
  opacity: 0.9;
}
.draggable:hover,
.draggable:focus {
  opacity: 1;
}
</style>
