<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div
        class="text-right col-lg-12 d-flex flex-column justify-content-center"
      >
        <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
          <router-link
            :to="{ name: 'Meal Templates' }"
            class="ms-3 btn btn-white text-success"
          >
            Back
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h5 class="font-weight-bolder">Meal Template</h5>
          </div>
          <loading-spinner
            :loading="loading.mealTemplate || formSubmitted"
            :screen-center="true"
          />
          <div v-show="!loading.mealTemplate" class="card-body pt-0">
            <div class="row">
              <div class="mt-3 col-12 col-sm-3">
                <label for="title">Title</label>
                <input
                  id="title"
                  v-model="mealTemplate.title"
                  class="form-control"
                  type="text"
                  placeholder="Meal Template title"
                />
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <label>Days per week</label>
                <select
                  id="days-per-week"
                  v-model="mealTemplate.days_per_week"
                  class="form-control"
                  @change="handleDaysPerWeekChange(event)"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <label>Number of weeks</label>
                <select
                  id="num-of-weeks"
                  v-model="mealTemplate.num_of_weeks"
                  class="form-control"
                  @change="handleNumOfWeeksChange($event)"
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-3">
                <argon-select
                  v-if="mealTemplateTypeChoices.length > 0"
                  id="type"
                  label="Type"
                  :options="{
                    choices: mealTemplateTypeChoices,
                    searchEnabled: false,
                  }"
                  :disabled="!$can('dropdown', 'meal_template_types')"
                  :model-value="mealTemplate.type"
                  @update:model-value="mealTemplate.type = $event"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Allergens</label>
                <select
                  id="allergens"
                  v-model="mealTemplate.allergens"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Protein category</label>
                <select
                  id="protein-to-avoid"
                  v-model="mealTemplate.protein_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Carb to avoid</label>
                <select
                  id="carb-to-avoid"
                  v-model="mealTemplate.carb_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Veg to avoid</label>
                <select
                  id="veg-to-avoid"
                  v-model="mealTemplate.veg_to_avoid"
                  class="form-control"
                  multiple
                ></select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <argon-select
                  id="status"
                  v-model="mealTemplate.status"
                  label="Status"
                  :options="{
                    choices: statusChoices,
                    searchEnabled: false,
                  }"
                ></argon-select>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <argon-select
                  v-if="canChangeUser"
                  id="meal-template-assigned-to"
                  :disabled="!canChangeUser"
                  label="Assigned to"
                  :model-value="mealTemplateAssignedTo"
                  :search-options-function="apiUsers.searchOptions"
                  :search-function="apiUsers.dropdown"
                  :label-fields="{
                    id: 'id',
                    value: 'id',
                    label: 'name',
                  }"
                  @update:model-value="handleUserChange"
                />
              </div>
            </div>
            <div
              class="text-right col-lg-12 d-flex flex-column justify-content-center"
            >
              <div class="mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0">
                <submit-form-button
                  :form-submitted="formSubmitted"
                  default-button-text="Download"
                  @click="download()"
                />
                <submit-form-button
                  class="ms-3"
                  :form-submitted="formSubmitted"
                  default-button-text="Save"
                  @click="save()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading.mealTemplateDays" class="row mt-4">
      <div class="col-12">
        <inactive-meals-card
          v-if="hasInactiveMeals"
          class="mb-4"
          :meals="mealTemplateDays"
          :meal-template="mealTemplate"
          @delete-meal="deleteInactiveMeal"
          @set-selected-meal="setSelectedInactiveMeal"
        />
      </div>
      <div class="col-12">
        <div class="card">
          <div class="">
            <div class="card-header pb-0 row m-0">
              <div class="col-6">
                <h5 class="mb-3">Daily Meals</h5>
                <div class="mb-3">
                  <list-badge :list-items="getTotalDataList" />
                </div>
              </div>
              <div class="container col-6 position-relative">
                <DraggableComponent :is-resizing="isResizing">
                  <ResizableComponent @resizing="handleResizing">
                    <MealItemSummary
                      v-if="mealTemplateSummary"
                      :meal-template-summary="mealTemplateSummary"
                    />
                  </ResizableComponent>
                </DraggableComponent>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="nav-wrapper position-relative">
                <nav-pills
                  :items="mealTemplateWeekNavItems()"
                  :default-active-tab="selected.week"
                  @set-active-tab="setActiveWeek"
                />
              </div>
              <div id="items-tab-content" class="tab-content mt-2">
                <div
                  v-for="(week, weekKey) in parseInt(mealTemplate.num_of_weeks)"
                  :id="'tab-content-items-' + week"
                  :key="weekKey"
                  :class="'tab-pane fade' + (week == 1 ? ' active show' : '')"
                  role="tabpanel"
                >
                  <list-badge
                    :list-items="getWeeklyListData(mealTemplateDays[week])"
                  />
                  <div class="d-flex align-items-center">
                    <div
                      v-for="(sourceWeek, key) in mealTemplate.num_of_weeks"
                      :key="key"
                    >
                      <button
                        v-if="showCopyWeekButton(sourceWeek, selected.week)"
                        type="button"
                        class="mb-0 btn btn-xs bg-gradient-success me-3 mt-3"
                        @click="copyWeekData(selected.week, sourceWeek)"
                      >
                        <i class="fas fa-copy pe-2" aria-hidden="true"></i>
                        Week {{ sourceWeek }} to Week {{ selected.week }}
                      </button>
                    </div>
                    <submit-form-button
                      v-if="showResetWeekButton(selected.week)"
                      :loading="formSubmitted"
                      :default-button-text="`Reset Week ${selected.week}`"
                      class="mb-0 btn btn-xs bg-gradient-danger me-3 mt-3"
                      @click="resetWeek(selected.week)"
                    />
                  </div>
                  <nav-pills
                    class="mt-4"
                    :items="mealTemplateDayNavItems(week)"
                    :default-active-tab="selected.day"
                    @set-active-tab="setActiveDay"
                  />
                  <div id="tab-content-days" class="row mt-2 tab-content">
                    <template
                      v-for="(day, dayKey) in parseInt(
                        mealTemplate.days_per_week
                      )"
                      :key="dayKey"
                      ><div
                        v-if="week === selected.week && day === selected.day"
                        :id="'tab-content-day-' + week + '-' + day"
                        role="tabpanel"
                        class="tab-pane fade active show"
                      >
                        <list-badge :list-items="getDailyListData" />
                        <div
                          v-if="
                            hasCopyableMeals &&
                            mealTemplateDays[selected.week][selected.day]
                              .length === 0
                          "
                          class="d-flex align-items-center mt-3"
                        >
                          Copy meals from week
                          <select
                            id="week-selector"
                            v-model="copyMeals.weekFrom"
                            class="form-control w-auto d-inline mx-1"
                          >
                            <option
                              v-for="weekWithMeal in weeksWithMeals"
                              :key="weekWithMeal"
                              :value="weekWithMeal"
                            >
                              {{ weekWithMeal }}
                            </option>
                          </select>
                          day
                          <select
                            id="day-selector"
                            v-model="copyMeals.dayFrom"
                            class="form-control w-auto d-inline mx-1"
                          >
                            <option
                              v-for="dayWithMeal in daysWithMeals"
                              :key="dayWithMeal"
                              :value="dayWithMeal"
                            >
                              {{ dayWithMeal }}
                            </option>
                          </select>
                          <button
                            type="button"
                            class="mb-0 btn btn-xs bg-gradient-success ms-3"
                            @click="copyMealsFromWeekDay()"
                          >
                            <i class="fas fa-copy pe-2" aria-hidden="true"></i>
                            Copy
                          </button>
                        </div>
                        <div
                          v-if="
                            mealTemplateDays[selected.week][selected.day]
                              .length > 0
                          "
                        >
                          <submit-form-button
                            :loading="formSubmitted"
                            :default-button-text="`Reset Week ${selected.week} Day ${selected.day}`"
                            class="mb-0 btn btn-xs bg-gradient-danger me-3 mt-3"
                            @click="resetWeekDay(selected)"
                          />
                        </div>
                        <div
                          id="meal-section"
                          :ref="`mealsSection${week}${day}`"
                          class="row mt-4"
                        >
                          <div
                            v-for="(meal, mealKey) in mealTemplateDays[week][
                              day
                            ]"
                            :key="mealKey"
                            class="col-12 col-lg-4 col-md-6 mb-3"
                          >
                            <meal-item
                              v-if="meal.dayMeals?.length > 0"
                              class="h-100"
                              :meal="meal"
                              :meal-template="mealTemplate"
                              @delete-meal="deleteMealTemplateDayMeals(mealKey)"
                              @set-selected-meal="setSelectedMeal(mealKey)"
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="d-flex align-items-center mt-3">
                      <button
                        v-for="(mealType, mealTypeKey) in mealTypes"
                        :key="mealTypeKey"
                        type="button"
                        class="mb-0 btn btn-xs bg-gradient-success me-3"
                        @click="addMealToSelectedDay(mealTypeKey)"
                      >
                        <i class="fas fa-plus pe-2" aria-hidden="true"></i>
                        Add
                        {{ mealTypeKey.replace(/_/g, " ") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <meal-modal
      v-if="mealModal.show"
      :modal-id="mealModal.id"
      :initial-data="selected.meal"
      :meal-types="mealTypes"
      :meal-template="mealTemplate"
      :daily-data="getDailyData"
      :form-submitted="formSubmitted"
      @close="clearSelectedMeal"
      @save="saveMealTemplateDays($event)"
    />
  </div>
</template>

<script>
import API from "@/services/api";
import ApiAllergens from "@/services/apiAllergens";
import ApiMealTemplates from "@/services/apiMealTemplates";
import { showConfirmation, showMessage } from "@/assets/js/show-message";
import { formatDataToChoicesJs, initChoices } from "@/assets/js/init-choices";
import {
  dateFormat,
  downloadFile,
  handleError,
  handleResponse,
} from "@/lib/helpers";
import ApiProducts from "@/services/apiProducts";
import LoadingSpinner from "@/components/LoadingSpinner";
import MealModal from "@/components/MealModal.vue";
import { closeModalObject, showModalObject } from "@/lib/bootstrap";
import ListBadge from "@/components/ListBadge";
import MealItemSummary from "@/components/MealItemSummary.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import ArgonSelect from "@/components/ArgonSelect.vue";
import { mealIsInactive, mealPriceNutritionList } from "@/lib/mealHelper";
import MealItem from "@/components/MealItem.vue";
import NavPills from "@/components/NavPills.vue";
import apiMealTemplates from "@/services/apiMealTemplates";
import InactiveMealsCard from "@/views/pages/Config/MealTemplates/components/InactiveMealsCard.vue";
import apiUsers from "@/services/apiUsers";
import { mapGetters } from "vuex";
import constants from "@/constants";
import DraggableComponent from "@/components/DraggableComponent.vue";
import ResizableComponent from "@/components/ResizableComponent.vue";
export default {
  name: "Edit",
  components: {
    InactiveMealsCard,
    NavPills,
    MealItem,
    MealItemSummary,
    ArgonSelect,
    SubmitFormButton,
    ListBadge,
    LoadingSpinner,
    MealModal,
    DraggableComponent,
    ResizableComponent,
  },
  data() {
    return {
      isResizing: false,
      mealModal: {
        show: false,
        id: "meal-modal",
      },
      formSubmitted: false,
      selected: {
        week: 1,
        day: 1,
        mealKey: null,
        meal: {},
        removeNew: false,
      },
      mealTemplate: {
        id: this.$route.params.id,
        days_per_week: 5,
        num_of_weeks: 4,
        title: "",
        type: "standard",
        status: "pending",
        allergens: [],
        protein_to_avoid: [],
        carb_to_avoid: [],
        veg_to_avoid: [],
      },
      mealTemplateDays: {},
      dataWeeks: [],
      loading: {
        mealTemplate: true,
        mealTemplateDays: true,
      },
      mealTypes: {},
      mealTemplateTypeChoices: [],
      copyMeals: {
        weekFrom: 1,
        dayFrom: 1,
      },
      statusChoices: [],
      mealTemplateAssignedTo: "",
      mealTemplateSummary: {},
    };
  },
  computed: {
    canChangeUser() {
      return this.$ability.can("update", "meal_template_assign_users");
    },
    ...mapGetters({
      currentUserId: "auth/getCurrentUserId",
    }),
    apiUsers() {
      return apiUsers;
    },
    hasInactiveMeals() {
      for (let week = 1; week <= this.mealTemplate.num_of_weeks; week++) {
        for (let day = 1; day <= this.mealTemplate.days_per_week; day++) {
          if (
            this.mealTemplateDays[week] &&
            this.mealTemplateDays[week][day] &&
            this.mealTemplateDays[week][day].some((meal) =>
              mealIsInactive(meal.mealTemplateDayMeals?.data)
            )
          ) {
            return true;
          }
        }
      }
      return false;
    },

    hasCopyableMeals() {
      return this.weeksWithMeals.some((week) =>
        Object.values(this.mealTemplateDays[week]).some((day) => day.length > 0)
      );
    },
    weeksWithMeals() {
      let weeksWithMeals = [];
      for (let week = 1; week <= this.mealTemplate.num_of_weeks; week++) {
        for (let day = 1; day <= this.mealTemplate.days_per_week; day++) {
          if (
            this.mealTemplateDays[week] &&
            this.mealTemplateDays[week][day] &&
            this.mealTemplateDays[week][day].length > 0
          ) {
            weeksWithMeals.push(week);
            break;
          }
        }
      }
      return weeksWithMeals;
    },

    daysWithMeals() {
      let daysWithMeals = [];
      if (this.mealTemplateDays[this.copyMeals.weekFrom]) {
        for (let day = 1; day <= this.mealTemplate.days_per_week; day++) {
          if (
            this.mealTemplateDays[this.copyMeals.weekFrom][day] &&
            this.mealTemplateDays[this.copyMeals.weekFrom][day].length > 0
          ) {
            daysWithMeals.push(day);
          }
        }
      }
      return daysWithMeals;
    },
    getDailyData() {
      let total = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        price: 0,
      };
      if (
        !this.mealTemplateDays[this.selected.week] ||
        !this.mealTemplateDays[this.selected.week][this.selected.day]
      ) {
        return total;
      }
      const data = this.mealTemplateDays[this.selected.week][this.selected.day];
      if (!data) {
        return total;
      }

      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (!data[i].dayMeals) {
            continue;
          }
          let dayMeals = data[i].dayMeals;
          for (let j = 0; j < dayMeals.length; j++) {
            let meal = dayMeals[j];

            total.calories += parseFloat(meal.kcal);
            total.carbs += parseFloat(meal.carb);
            total.fat += parseFloat(meal.fat);
            total.protein += parseFloat(meal.pro);
            total.price += parseFloat(meal.price);
          }
        }
      }
      return total;
    },
    getDailyListData() {
      return mealPriceNutritionList(this.getDailyData, false);
    },

    getTotalData() {
      let calories = 0,
        carbs = 0,
        fat = 0,
        protein = 0,
        price = 0;
      for (let week in this.mealTemplateDays) {
        let days = this.mealTemplateDays[week];
        for (let day in days) {
          for (let i in days[day]) {
            for (let j in days[day][i].mealTemplateDayMeals) {
              for (let k in days[day][i].mealTemplateDayMeals[j]) {
                let productVariation =
                  days[day][i].mealTemplateDayMeals[j][k].productVariation.data;
                calories += parseFloat(productVariation.kcal);
                carbs += parseFloat(productVariation.carb);
                fat += parseFloat(productVariation.fat);
                protein += parseFloat(productVariation.pro);
                price += parseFloat(productVariation.price);
              }
            }
          }
        }
      }
      return {
        price: price,
        calories: calories,
        carbs: carbs,
        fat: fat,
        protein: protein,
      };
    },
    getTotalDataList() {
      const total = this.getTotalData;
      return mealPriceNutritionList(total, false);
    },
  },
  async mounted() {
    this.mealTypes = API.getProductTypes();
    await this.setMealTemplate();
    await this.initDaysPerWeek();
    await this.initNumOfWeeks();
    await this.setMealTemplateTypeChoices();
    await this.initStatusChoices();
    await this.initAllergenChoices();
    await this.initProteinToAvoidChoices();
    await this.initCarbToAvoidChoices();
    await this.initVegToAvoidChoices();
  },
  methods: {
    handleResizing(isResizing) {
      this.isResizing = isResizing;
    },
    async getTemplateSummary() {
      try {
        const response = await API.templateSummery(this.mealTemplate.id);
        if (response.status === 200) {
          this.mealTemplateSummary = response.data;
        } else {
          console.error("Error fetching template summary", response.message);
        }
      } catch (error) {
        handleError(error);
      }
    },
    getWeeklyListData(days) {
      let total = {
        calories: 0,
        carbs: 0,
        fat: 0,
        protein: 0,
        price: 0,
      };
      for (let day in days) {
        for (let i in days[day]) {
          for (let j in days[day][i].mealTemplateDayMeals) {
            for (let k in days[day][i].mealTemplateDayMeals[j]) {
              let productVariation =
                days[day][i].mealTemplateDayMeals[j][k].productVariation.data;
              total.calories += parseFloat(productVariation.kcal);
              total.carbs += parseFloat(productVariation.carb);
              total.fat += parseFloat(productVariation.fat);
              total.protein += parseFloat(productVariation.pro);
              total.price += parseFloat(productVariation.price);
            }
          }
        }
      }
      return mealPriceNutritionList(total, false);
    },

    async setMealTemplateTypeChoices() {
      const response = await ApiMealTemplates.getMealTemplateTypeList();
      this.mealTemplateTypeChoices = formatDataToChoicesJs(
        await handleResponse(response),
        [],
        "enum"
      );
    },
    async initDaysPerWeek() {
      await initChoices(
        "days-per-week",
        {
          choices: formatDataToChoicesJs(
            ApiMealTemplates.getDaysPerWeekOptions(
              constants.mealTemplate.type.AUTOMATIC
            ),
            []
          ),
          searchEnabled: false,
        },
        this.mealTemplate.days_per_week
      );
    },
    async initNumOfWeeks() {
      await initChoices(
        "num-of-weeks",
        {
          choices: formatDataToChoicesJs(
            ApiMealTemplates.getWeeksOptions(
              constants.mealTemplate.type.AUTOMATIC
            ),
            []
          ),
          searchEnabled: false,
        },
        this.mealTemplate.num_of_weeks
      );
    },
    async initStatusChoices() {
      const response = await ApiMealTemplates.getMealTemplateStatusList().catch(
        handleError
      );
      this.statusChoices = formatDataToChoicesJs(
        await handleResponse(response),
        "",
        "enum"
      );
    },
    async initAllergenChoices() {
      const response = await ApiAllergens.get("start=0&length=-1").catch(
        handleError
      );
      if (response.status == 200) {
        initChoices(
          "allergens",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.allergens
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initProteinToAvoidChoices() {
      const response = await ApiProducts.getProteinCategories().catch(
        handleError
      );

      if (response.status == 200) {
        await initChoices(
          "protein-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.protein_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initCarbToAvoidChoices() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "carb",
        status: "active",
      }).catch(handleError);

      if (response.status == 200) {
        initChoices(
          "carb-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.carb_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async initVegToAvoidChoices() {
      const response = await ApiProducts.get({
        start: 0,
        length: -1,
        food_type: "veg",
        status: "active",
      }).catch(handleError);

      if (response.status == 200) {
        initChoices(
          "veg-to-avoid",
          {
            choices: API.formatDataToChoicesJs(response.data.data),
            allowHTML: true,
            removeItemButton: true,
            removeItems: true,
          },
          this.mealTemplate.veg_to_avoid
        );
      } else {
        showMessage(response.message, "error");
      }
    },
    async setMealTemplate() {
      this.loading.mealTemplate = true;
      const appInstance = this;

      const response = await ApiMealTemplates.show(this.mealTemplate.id).catch(
        handleError
      );
      if (response.status === 200) {
        this.mealTemplate = {
          id: response.data.data.id,
          title: response.data.data.title,
          type: response.data.data.type,
          allergens:
            response.data.data.allergens == null
              ? []
              : response.data.data.allergens,
          carb_to_avoid:
            response.data.data.carb_to_avoid == null
              ? []
              : response.data.data.carb_to_avoid,
          veg_to_avoid:
            response.data.data.veg_to_avoid == null
              ? []
              : response.data.data.veg_to_avoid,
          protein_to_avoid:
            response.data.data.protein_to_avoid == null
              ? []
              : response.data.data.protein_to_avoid,
          days_per_week: response.data.data.days_per_week,
          num_of_weeks: response.data.data.num_of_weeks,
          status: response.data.data.status,
          mealTemplateDays: response.data.data.mealTemplateDays.data,
        };
        this.mealTemplateAssignedTo = response.data.data.assigned_to;
        await this.setMealTemplateDays(this.mealTemplate.mealTemplateDays);
      } else {
        if (response.status === 404) {
          appInstance.$router.push({ name: "Meal Templates List" });
        }
        showMessage(response.message, "error");
      }
      await this.getTemplateSummary();
      this.loading.mealTemplate = false;
    },

    normalizeMealTemplateDays() {
      Object.keys(this.mealTemplateDays).forEach((week) => {
        if (parseInt(week) > this.mealTemplate.num_of_weeks) {
          delete this.mealTemplateDays[week];
        }
      });

      for (let week = 1; week <= this.mealTemplate.num_of_weeks; week++) {
        // Initialize the week if it does not exist
        if (!this.mealTemplateDays[week]) {
          this.mealTemplateDays[week] = {};
        }

        Object.keys(this.mealTemplateDays[week]).forEach((day) => {
          if (parseInt(day) > this.mealTemplate.days_per_week) {
            delete this.mealTemplateDays[week][day];
          }
        });

        for (let day = 1; day <= this.mealTemplate.days_per_week; day++) {
          if (!this.mealTemplateDays[week][day]) {
            this.mealTemplateDays[week][day] = [];
          }
        }
      }
    },
    async setMealTemplateDays(mealTemplateDays) {
      this.loading.mealTemplateDays = true;
      this.mealTemplateDays = {};
      this.dataWeeks = [];
      for (let i in mealTemplateDays) {
        let week = mealTemplateDays[i].week;
        let day = mealTemplateDays[i].day;

        if (!this.dataWeeks.includes(week)) {
          this.dataWeeks.push(week);
        }

        if (!this.mealTemplateDays[week]) {
          this.mealTemplateDays[week] = {};
        }

        if (!this.mealTemplateDays[week][day]) {
          this.mealTemplateDays[week][day] = [];
        }

        const transformedDayMeals = [];
        if (
          mealTemplateDays[i].mealTemplateDayMeals &&
          mealTemplateDays[i].mealTemplateDayMeals.data
        ) {
          const dayMealsData = mealTemplateDays[i].mealTemplateDayMeals.data;

          for (let k in dayMealsData) {
            const mealData = dayMealsData[k].productVariation.data;

            transformedDayMeals.push({
              id: mealData.id,
              product_id: mealData.product_id,
              variation_id: mealData.id,
              type: mealData["product.type"],
            });
          }
        }
        mealTemplateDays[i].meal_type = mealTemplateDays[i].type;
        mealTemplateDays[i].dayMeals = this.getDayMeals(mealTemplateDays[i]);
        this.mealTemplateDays[week][day].push(mealTemplateDays[i]);
      }
      this.normalizeMealTemplateDays();
      this.loading.mealTemplateDays = false;
    },
    getDayMeals(mealTemplateDay) {
      return mealTemplateDay.mealTemplateDayMeals.data.map((meal) => {
        const mealData = meal.productVariation.data;
        const allergens = mealData["product.allergens"].map((allergen) => ({
          id: allergen.id,
          name: allergen.name,
        }));

        return {
          id: mealData.id,
          title: `${mealData["product.name"]} ${mealData.weight}${mealData.unit}`,
          name: `${mealData["product.name"]} ${mealData.weight}${mealData.unit}`,
          product_id: mealData.product_id,
          type: mealData["product.type"],
          sub_type: mealData["product.sub_type"],
          status: mealData["product.status"],
          kcal: mealData.kcal,
          fat: mealData.fat,
          carb: mealData.carb,
          pro: mealData.pro,
          price: mealData.price,
          weight: mealData.weight,
          unit: mealData.unit,
          app_image: mealData["product.app_image"],
          allergens: allergens,
          client_allergens: [],
          client_protein_category_dislike: [],
          client_veg_dislike: [],
          client_carb_dislike: [],
          created_at: meal.created_at,
        };
      });
    },

    async save() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = {};
      for (let i in this.mealTemplate) {
        if (this.mealTemplate[i]) {
          formData[i] = this.mealTemplate[i];
        }
      }
      formData._method = "PATCH";
      let response = await ApiMealTemplates.update(
        this.mealTemplate.id,
        formData
      ).catch(handleError);
      if (response.status == 200) {
        showMessage("Success. Meal Template saved.", "success");
        await this.getTemplateSummary();
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    async delete() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          ApiMealTemplates.delete(this.mealTemplate.id)
            .then(() => {
              showMessage(
                "Success. Meal Template deleted.",
                {
                  name: "fa-check-circle",
                  class: "text-success",
                },
                1500,
                () => {
                  this.$router.push("/config/meal-templates");
                }
              );
            })
            .catch((err) => {
              let message = err.message;
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                message = err.response.data.message;
              }
              showMessage(
                message,
                {
                  name: "fa-exclamation-triangle",
                  class: "text-danger",
                },
                3500
              );
            });
        }
      });
    },

    showCopyWeekButton(week, selectedWeek) {
      if (
        this.dataWeeks.includes(week) &&
        !this.dataWeeks.includes(selectedWeek)
      ) {
        return true;
      }
      return false;
    },
    showResetWeekButton(week) {
      return this.dataWeeks.includes(week);
    },
    async resetWeek(week) {
      const confirmation = await showConfirmation({
        title: `Are you sure you want to reset week ${week}?`,
      });
      if (!confirmation) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiMealTemplates
        .resetWeek(this.mealTemplate.id, {
          week: week,
        })
        .catch(handleError);
      await handleResponse(response, this.setMealTemplate);
      this.formSubmitted = false;
    },
    async resetWeekDay(selected) {
      const confirmation = await showConfirmation({
        title: `Are you sure you want to reset week ${selected.week} day ${selected.day}?`,
      });
      if (!confirmation) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await apiMealTemplates
        .resetWeekDay(this.mealTemplate.id, {
          week: selected.week,
          day: selected.day,
        })
        .catch(handleError);
      await handleResponse(response, this.setMealTemplate);
      this.formSubmitted = false;
    },
    async copyWeekData(to_week, from_week) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const formData = new FormData();
      formData.append("from_week", from_week);
      formData.append("to_week", to_week);
      const response = await API.copyWeekDetails(
        this.mealTemplate.id,
        formData
      ).catch(handleError);
      await handleResponse(response, this.setMealTemplate);
      this.formSubmitted = false;
    },
    async copyMealsFromWeekDay() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const data = {
        week_from: this.copyMeals.weekFrom,
        day_from: this.copyMeals.dayFrom,
        week_to: this.selected.week,
        day_to: this.selected.day,
      };
      const response = await apiMealTemplates
        .copyWeekDay(this.mealTemplate.id, data)
        .catch(handleError);
      await handleResponse(response, this.setMealTemplate);
      this.formSubmitted = false;
    },
    async saveMealTemplateDays(updatedData) {
      if (this.formSubmitted) {
        return;
      }
      const mealTemplateArr = {
        day: updatedData.day,
        week: updatedData.week,
        type: updatedData.meal_type,
        mealTemplateDayMeals: {
          data: [],
        },
      };

      updatedData.dayMeals.forEach((item) => {
        const transformedItem = {
          productVariation: {
            data: {
              id: item.id,
              product_id: item.product_id,
              "product.allergens": item.allergens || [],
              type: item.product_type,
            },
          },
        };
        mealTemplateArr.mealTemplateDayMeals.data.push(transformedItem);
      });

      this.mealTemplateDays[this.selected.week][this.selected.day][
        this.selected.mealKey
      ] = { ...mealTemplateArr };

      await this.upsertMealTemplateDayMeals("Meal saved successfully");
    },
    async upsertMealTemplateDayMeals(successMessage) {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      let formData = {
        week: this.selected.week,
        day: this.selected.day,
        meal_template_days: [],
      };

      for (let i in this.mealTemplateDays[this.selected.week][
        this.selected.day
      ]) {
        let meals = [];
        let dayMeals =
          this.mealTemplateDays[this.selected.week][this.selected.day][i]
            .mealTemplateDayMeals.data;
        for (let j in dayMeals) {
          let meal = dayMeals[j];
          meals.push({
            product_id: parseInt(meal.productVariation.data.product_id),
            product_variation_id: parseInt(meal.productVariation.data.id),
          });
        }
        formData.meal_template_days.push({
          type: this.mealTemplateDays[this.selected.week][this.selected.day][i]
            .type,
          meals: meals,
        });
      }
      const response = await ApiMealTemplates.upsertMealTemplateDays(
        this.mealTemplate.id,
        formData
      ).catch(handleError);
      if (response.status === 200) {
        await this.setMealTemplate();
        showMessage(successMessage, "success");
        this.selected.removeNew = false;
        closeModalObject(this.mealModal);
      } else {
        await this.setMealTemplate();
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    deleteInactiveMeal(key, week, day) {
      this.selected.week = week;
      this.selected.day = day;
      this.deleteMealTemplateDayMeals(key);
    },
    deleteMealTemplateDayMeals(key) {
      if (this.formSubmitted) {
        return;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger ms-2",
          cancelButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.mealTemplateDays[this.selected.week][this.selected.day].splice(
            key,
            1
          );
          this.upsertMealTemplateDayMeals("Meal deleted successfully");
        }
      });
    },
    clearSelectedMeal() {
      if (this.selected.removeNew) {
        this.mealTemplateDays[this.selected.week][this.selected.day].splice(
          this.selected.mealKey,
          1
        );
      }
      this.selected.mealKey = null;
      this.selected.meal = {};
      closeModalObject(this.mealModal);
    },

    async addMealToSelectedDay(type) {
      if (!this.mealTemplateDays[this.selected.week]) {
        this.mealTemplateDays[this.selected.week] = {};
      }

      if (!this.mealTemplateDays[this.selected.week][this.selected.day]) {
        this.mealTemplateDays[this.selected.week][this.selected.day] = [];
      }
      const newMeal = {
        day: this.selected.day,
        week: this.selected.week,
        meal_type: type,
        dayMeals: [],
      };
      this.mealTemplateDays[this.selected.week][this.selected.day].push(
        newMeal
      );

      // // this.mealType = type;
      this.selected.removeNew = true;

      const selectedWeek = this.mealTemplateDays[this.selected.week];
      const selectedDay = selectedWeek ? selectedWeek[this.selected.day] : null;
      const length = selectedDay ? selectedDay.length : 0;

      this.setSelectedMeal(length - 1);
    },
    setSelectedInactiveMeal(key, week, day) {
      this.selected.week = week;
      this.selected.day = day;
      this.setSelectedMeal(key);
    },
    setSelectedMeal(key) {
      this.selected.meal =
        this.mealTemplateDays[this.selected.week][this.selected.day][key];
      this.selected.mealKey = key;

      showModalObject(this.mealModal, this);
    },
    async download() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const response = await ApiMealTemplates.show(this.mealTemplate.id, {
        action: "download",
      }).catch(handleError);
      if (response.status === 200) {
        downloadFile(
          response.data,
          "meal-template-" + dateFormat("current", "timestamp") + ".pdf",
          response.headers["content-disposition"]
        );
      } else {
        showMessage(response.message, "error");
      }
      this.formSubmitted = false;
    },
    setActiveWeek(week) {
      this.selected.week = week;
      this.selected.day = 1;
    },
    setActiveDay(day) {
      this.selected.day = day;
    },
    mealTemplateWeekNavItems() {
      const result = [];
      for (let week = 1; week <= this.mealTemplate.num_of_weeks; week++) {
        result.push({
          ref: week,
          target: `#tab-content-items-${week}`,
          text: `Week ${week}`,
          activeTab: week,
        });
      }
      return result;
    },
    mealTemplateDayNavItems(week) {
      const result = [];
      for (let day = 1; day <= this.mealTemplate.days_per_week; day++) {
        result.push({
          ref: day,
          target: `#tab-content-day-${week}-${day}`,
          text: `Day ${day}`,
          activeTab: day,
        });
      }
      return result;
    },
    async handleUserChange(assignedTo) {
      if (!assignedTo || this.mealTemplateAssignedTo == assignedTo) {
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      this.loading.mealTemplate = true;
      this.mealTemplateAssignedTo = assignedTo;
      const response = await apiMealTemplates
        .changeUser(this.mealTemplate.id, {
          user_id: this.mealTemplateAssignedTo,
        })
        .catch(handleError);
      await handleResponse(response);
      this.loading.mealTemplate = false;
      this.formSubmitted = false;
    },
    async handleNumOfWeeksChange() {
      this.selected.week = 1;
      await this.$nextTick();
      this.selected.day = 1;
      this.setMealTemplateDays(this.mealTemplate.mealTemplateDays);
    },
    handleDaysPerWeekChange() {
      if (this.selected.day > parseInt(this.mealTemplate.days_per_week)) {
        this.selected.day = 1;
      }
      this.setMealTemplateDays(this.mealTemplate.mealTemplateDays);
    },
  },
};
</script>
<style scoped>
.resizer-content {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #e0f7fa;
  border: 1px solid #00796b;
}
</style>
