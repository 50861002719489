<template>
  <form @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-12">
        <argon-input
          id="modal-form-amount"
          label="Amount"
          type="text"
          placeholder="Amount"
          :model-value="form.amount"
          :errors="v$.form.amount.$errors"
          @update:model-value="form.amount = $event"
        />
      </div>
      <div class="col-12">
        <argon-textarea
          id="modal-form-description"
          label="Description"
          placeholder="Description"
          :model-value="form.description"
          :errors="v$.form.description.$errors"
          @update:model-value="form.description = $event"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3 d-flex justify-content-end">
        <submit-form-button
          default-button-text="Save"
          :form-submitted="formSubmitted"
        />
        <span
          v-if="showCloseButton"
          class="btn bg-gradient-secondary btn-md ms-2 mb-0"
          @click="$emit('close')"
          >Close</span
        >
      </div>
    </div>
  </form>
</template>
<script>
import ArgonInput from "@/components/ArgonInput.vue";
import SubmitFormButton from "@/components/SubmitFormButton.vue";
import { useVuelidate } from "@vuelidate/core";
import { handleError, handleResponse } from "@/lib/helpers";
import { helpers, required, validatorMessages } from "@/lib/validators";
import { showMessage } from "@/assets/js/show-message";
import apiWallet from "@/services/apiWallet";
import ArgonTextarea from "@/components/ArgonTextarea.vue";

export default {
  name: "WalletAddForm",
  components: {
    ArgonTextarea,
    SubmitFormButton,
    ArgonInput,
  },
  props: {
    clientId: {
      type: [String, Number],
      default: "",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    withdraw: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "save"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formSubmitted: false,
      form: {
        amount: "",
      },
    };
  },

  async mounted() {
    this.form = {
      client_id: this.clientId,
      amount: "",
      description: "",
    };
  },
  methods: {
    async formSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        showMessage(validatorMessages.allRequired, "error");
        return;
      }
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      const formData = {
        ...this.form,
        ...{ amount: this.form.amount * 100 },
      };
      let response;
      try {
        if (this.withdraw) {
          response = await apiWallet.withdraw(formData);
        } else {
          response = await apiWallet.add(formData);
        }
        await handleResponse(response);
        if (response.status === 200) {
          this.$emit("save");
        }
      } catch (error) {
        const handledError = handleError(error);
        showMessage(
          handledError.message || "An unexpected error occurred.",
          "error"
        );
      }
      this.formSubmitted = false;
    },
  },

  validations() {
    return {
      form: {
        amount: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
        description: {
          required: helpers.withMessage(validatorMessages.required, required),
        },
      },
    };
  },
};
</script>
<style scoped></style>
