import { currencyFormat, numberFormat } from "@/lib/helpers";

export function mealPriceNutritionList(total, twoLines) {
  return [
    {
      label: "Calories",
      twoLines: twoLines,
      value: numberFormat(total.calories),
    },
    {
      label: "Carbs",
      twoLines: twoLines,
      value: numberFormat(total.carbs),
    },
    { label: "Fat", twoLines: twoLines, value: numberFormat(total.fat) },
    {
      label: "Pro",
      twoLines: twoLines,
      value: numberFormat(total.protein),
    },
    {
      label: "Price",
      twoLines: twoLines,
      value: currencyFormat(total.price),
    },
  ];
}
export function mealIsInactive(mealComponents) {
  if (!mealComponents) {
    return false;
  }
  return mealComponents.some(
    (mealComponent) =>
      mealComponent.productVariation.data["product.status"] === "inactive" ||
      mealComponent.productVariation.data.status === "inactive"
  );
}
