<template>
  <modal
    :modal-id="modalId"
    :dialog-class="dialogClass"
    @close="$emit('close')"
  >
    <template #header>
      <h6 v-if="walletWithdraw">Remove From Wallet</h6>
      <h6 v-else>Add to wallet</h6>
    </template>
    <wallet-add-form
      :client-id="clientId"
      :withdraw="walletWithdraw"
      @close="$emit('close')"
      @save="$emit('save', $event)"
    />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import WalletAddForm from "@/views/pages/Clients/components/WalletAddForm.vue";

export default {
  name: "WalletAddModal",
  components: {
    WalletAddForm,
    Modal,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    dialogClass: {
      type: String,
      default: "",
    },
    walletWithdraw: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: [String, Number],
      default: "",
    },
    invoiceId: {
      type: [String, Number],
      default: "",
    },
  },
  emits: ["close", "save"],
};
</script>
