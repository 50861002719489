import { httpApi } from "@/services/httpApi";

class apiWallet {
  async add(data) {
    return await httpApi.post("beetle/v1/wallet/add-to-wallet", data);
  }
  async withdraw(data) {
    return await httpApi.post("beetle/v1/wallet/withdraw-from-wallet", data);
  }
}

export default new apiWallet();
